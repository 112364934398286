<template>
  <div class="login-page">
    <bg-card variant="shadow">
      <div class="login-page__container">
        <img
          src="@/assets/images/logo.svg"
          class="login-page__container__logo"
        />
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitLogin)">
            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ failedRules }"
            >
              <bg-input
                class="login-page__container__input"
                type="email"
                v-model="loginData.email"
                placeholder="Email"
                @input="clearLoginErrorMessage"
                :error="!!(failedRules.required || failedRules.email)"
              />
              <bg-text
                id="requiredEmailError"
                size="label-2"
                class="login-page__container__error-message"
                v-if="failedRules.required"
                >Masukkan email</bg-text
              >
              <bg-text
                id="invalidEmailError"
                size="label-2"
                class="login-page__container__error-message"
                v-if="failedRules.email"
                >Gunakan format email seperti: mami@mamikos.com</bg-text
              >
            </ValidationProvider>

            <ValidationProvider
              name="password"
              rules="required"
              v-slot="{ failedRules }"
            >
              <bg-input-password
                class="login-page__container__input"
                v-model="loginData.password"
                placeholder="Password"
                @input="clearLoginErrorMessage"
                :error="!!failedRules.required"
              />
              <bg-text
                size="label-2"
                class="login-page__container__error-message"
                v-if="failedRules.required"
                >Masukkan password</bg-text
              >
            </ValidationProvider>

            <bg-text
              v-if="isErrorLogin"
              size="label-2"
              class="login-page__container__error-message"
              >{{ errorLoginMessage }}</bg-text
            >

            <bg-button
              class="login-page__container__button"
              variant="primary"
              size="lg"
              block
              type="submit"
              :loading="isLoading"
              >Login</bg-button
            >
          </form>
        </ValidationObserver>
      </div>
    </bg-card>
  </div>
</template>

<script>
import { BgInput, BgInputPassword, BgButton, BgCard, BgText } from 'bangul-vue';
import auth from '@admin/api/endpoints/auth';

export default {
  name: 'LoginPage',
  components: {
    BgInput,
    BgInputPassword,
    BgButton,
    BgCard,
    BgText,
  },
  data() {
    return {
      loginData: {
        email: '',
        password: '',
      },
      isErrorLogin: false,
      errorLoginMessage: '',
      isLoading: false,
    };
  },
  computed: {
    redirect() {
      const redirect =
        this.$route.query.redirect || /* istanbul ignore next */ '/';
      return decodeURIComponent(redirect);
    },
  },
  beforeCreate() {
    document.body.style.cssText = 'background: #e8e8e8; overflow: hidden';
  },
  methods: {
    submitLogin() {
      const { email, password } = this.loginData;
      this.isLoading = true;

      auth
        .issueToken({ email, password })
        .then(() => {
          this.isLoading = false;
          this.$router.push(this.redirect);
        })
        .catch(error => {
          let combinedMessage = null;
          if (error.response?.data.error?.details.length) {
            combinedMessage = error.response.data.error.details.join(', ');
          }
          const errorMessage =
            combinedMessage ||
            error.response?.data.error?.message ||
            'Terjadi kesalahan silakan coba lagi.';
          this.isLoading = false;
          this.isErrorLogin = true;
          this.errorLoginMessage = errorMessage;
        });
    },
    clearLoginErrorMessage() {
      this.isErrorLogin = false;
    },
  },
};
</script>

<style src="@admin/pages/auth/Login.scss" lang="scss" scoped></style>
